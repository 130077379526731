import card from '../../data/assets/icons/card.svg'
import cash from '../../data/assets/icons/cash.svg'
import loyalty from '../../data/assets/icons/loyalty.svg'
import loyaltyCard from '../../data/assets/icons/loyaltyCard.svg'
import loyaltyCash from '../../data/assets/icons/loyaltyCash.svg'
import loyaltyCashCard from '../../data/assets/icons/loyaltyCashCard.svg'

export const fillDataReportItems: any = (detailsummary: any) => {
  const dataReportItems: Array<any> = []

  dataReportItems.push({
    operationName: 'Cobros con tarjeta',
    operationCounter: detailsummary.cardCounter
      ? `${detailsummary.cardCounter.toString()} Operaciones`
      : '0 Operaciones',
    totalAmount: detailsummary.cardAmount ?? 0,
    icon: card
  })

  dataReportItems.push({
    operationName: 'Cobros en efectivo',
    operationCounter: detailsummary.cashCounter
      ? `${detailsummary.cashCounter.toString()} Operaciones`
      : '0 Operaciones',
    totalAmount: detailsummary.cashAmount ?? 0,
    icon: cash
  })

  dataReportItems.push({
    operationName: 'Cobros con puntos',
    operationCounter: detailsummary.loyaltyCounter
      ? `${detailsummary.loyaltyCounter.toString()} Operaciones`
      : '0 Operaciones',
    totalAmount: detailsummary.loyaltyAmount ?? 0,
    icon: loyalty
  })

  dataReportItems.push({
    operationName: 'Puntos + tarjeta',
    operationCounter: detailsummary.mixedLoyaltyCardCounter
      ? `${detailsummary.mixedLoyaltyCardCounter.toString()} Operaciones`
      : '0 Operaciones',
    totalAmount: detailsummary.mixedLoyaltyCardAmount ?? 0,
    icon: loyaltyCard
  })

  dataReportItems.push({
    operationName: 'Puntos + efectivo',
    operationCounter: detailsummary.mixedLoyaltyCashCounter
      ? `${detailsummary.mixedLoyaltyCashCounter.toString()} Operaciones`
      : '0 Operaciones',
    totalAmount: detailsummary.mixedLoyaltyCashAmount ?? 0,
    icon: loyaltyCash
  })

  dataReportItems.push({
    operationName: 'Tarjeta + efectivo',
    operationCounter: detailsummary.mixedLoyaltyCashCardCounter
      ? `${detailsummary.mixedLoyaltyCashCardCounter.toString()} Operaciones`
      : '0 Operaciones',
    totalAmount: detailsummary.mixedLoyaltyCashCardAmount ?? 0,
    icon: loyaltyCashCard
  })

  return dataReportItems
}
