// Packages
import React, { useEffect, useState } from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { GET_TOTALS_REPORT } from '../../graphql/queries/Reports'

// Styled Components
import {
  ReportContentHeader,
  StyledReportTitle,
  ReportContentTotals,
  StyledOperationsTypeReportContainer,
  TotalContent,
  ResumeContent
} from './styledComponents'

// Components
import CustomButton from '../../components/Storybook/customButton'
import Amount from '../../components/Amount'
import { useQuery } from '@apollo/client'
import { fillDataReportItems } from './utils'
import Icon from '../../components/Icon'
import { navigate } from 'gatsby'
import { getParameter } from '../../utils/queryString'
import Loading from '../../components/Shared/Loading'
interface TotalContentCardProps {
  operationName: string
  operationCounter: string
  totalAmount: number
  icon: string
}

const TotalContentCard: React.FC<TotalContentCardProps> = (
  totalContentCardProps
) => {
  const { icon, operationCounter, operationName, totalAmount } =
    totalContentCardProps

  return (
    <>
      <TotalContent>
        <Icon icon={icon} height={28} width={28} />
        <ResumeContent>
          <h2>{operationName}</h2>
          <p>{operationCounter}</p>
        </ResumeContent>
        <Amount
          amount={totalAmount.toFixed(2).toString()}
          amountSize={'18'}
          currecySize={'10'}
          centsSize={'10'}
          style={{ width: 'auto', float: 'right' }}
        />
      </TotalContent>
    </>
  )
}

const TotalOperationsContainer: React.FC = () => {
  const from = getParameter('from')
  const to = getParameter('to')
  const storeId = getParameter('storeId')
  const [totalOperations, setTotalOperations]: any = useState([])
  const { data: totalsReportData, loading: totalsReportLoading } = useQuery(
    GET_TOTALS_REPORT,
    {
      variables: { storeId, from, to },
      context: { clientName: 'api-instore' }
    }
  )

  useEffect(() => {
    if (
      totalsReportData &&
      totalsReportData.getOperationDetails &&
      totalsReportData.getOperationDetails.detailsummary &&
      !totalsReportLoading
    ) {
      const formatedOperationTotals: Array<any> = fillDataReportItems(
        totalsReportData.getOperationDetails.detailsummary
      )

      setTotalOperations(formatedOperationTotals)
    }
  }, [totalsReportData, totalsReportLoading])

  return (
    <>
      <ReportContentHeader>
        <CustomButton
          id="button-icon-back-sales-report"
          name="buttonIconBackSalesReport"
          className="button-icon-back-sales-report"
          dataTestId="data-test-button-icon-back-sales-report"
          icon={<LeftOutlined />}
          onClick={() => navigate(-1)}
        />
        <StyledReportTitle>RESUMEN DE OPERACIONES</StyledReportTitle>
      </ReportContentHeader>

      {totalsReportLoading ? (
        <Loading />
      ) : (
        <StyledOperationsTypeReportContainer>
          <ReportContentTotals>
            {totalOperations.map((operation: any) => {
              return <TotalContentCard {...operation} />
            })}
          </ReportContentTotals>
        </StyledOperationsTypeReportContainer>
      )}
    </>
  )
}

export default TotalOperationsContainer
