import React from 'react'
import WithPrivateRoute from '../../../../higherOrderComponents/WithPrivateRoute'
import TotalOperationsContainer from '../../../../containers/TotalOperationsContainer'
import LayoutMain from '../../../../layout'

const OperationsType: React.FC = () => {
  return (
    <LayoutMain>
      <WithPrivateRoute component={TotalOperationsContainer} />
    </LayoutMain>
  )
}

export default OperationsType
